import { ref, watch } from "vue";
import { ElMessage } from "element-plus"
import axios from 'axios';
import { LearningCourseHistory } from "@/types/learning_course_history";
import { LearningCourseRepository } from '@/repositories/LearningCourseRepository'
export const LearningCourseHistoryLogic = () => {

    const learningCourseHistoryErrors = ref("");
    const learningCourseHistoryLoading = ref(false)
    const learningCourseHistory = ref<LearningCourseHistory | null>(null)


    const getLearningCourseHistory = async (projectId: number, learning_course_id: number, user_id?: number) => {
        learningCourseHistoryLoading.value = true

        const repository: LearningCourseRepository = new LearningCourseRepository(projectId);
        try {
            learningCourseHistory.value = await repository.getHistory(learning_course_id, user_id);

            learningCourseHistoryLoading.value = false;

        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                learningCourseHistoryErrors.value = `取得に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }


    };

    const LearningCourseHistoryStateReturn = () => {
        return {
            learningCourseHistoryErrors,
            learningCourseHistoryLoading,
            learningCourseHistory,
            getLearningCourseHistory
        }
    }
    watch(learningCourseHistoryErrors, () => {
        if (learningCourseHistoryErrors.value == "") {
            return;
        }
        ElMessage({
            showClose: true,
            message: learningCourseHistoryErrors.value,
            type: 'error'
        });
        learningCourseHistoryErrors.value = "";
    })
    return {
        LearningCourseHistoryStateReturn,
        ...LearningCourseHistoryStateReturn()
    }
}


